import { RequestMethod } from '@lib/api/consts'
import internalJsonFetch from '@lib/api/internalJsonFetch'
import LocalStorageItem from '@lib/browsers/localStorage/LocalStorageItem'
import type { GeoipResult } from '@lib/geocoder/geoip'
import TrialApplicationForm from '@lib/questionnaire/trialApplication/TrialApplicationForm'
import ApiRoutes from '@lib/routes/ApiRoutes'
import type { GeolocateResponseData } from '@pages/api/v1/callbacks/geolocate'
import addDays from 'date-fns/addDays'
import isEmpty from 'lodash/isEmpty'

const GEOSTORE_LOCAL_STORAGE_KEY = 'geolocation'
export const GEOSTORE_VERSION = 1

type CachedGeoipResult = Partial<GeoipResult> & {
  expiresAt?: number
  version?: number
}

type LatLngLocation = {
  latitude?: number
  locationName?: string
  longitude?: number
}

const TTL_DAYS = 4

export function latLngFromGeolocation(geolocationResult: CachedGeoipResult) {
  return {
    latitude: geolocationResult.latitude,
    locationName: [geolocationResult.city, geolocationResult.stateIsoCode].join(
      ', ',
    ),
    longitude: geolocationResult.longitude,
  }
}

export function isValid(cachedGeolocation: CachedGeoipResult) {
  // there are two ways that we consider the cached geoip valid:
  // 1. if it has not expired, and has a lat/lng
  // 2. if it is <1 day old, even if it is missing a lat/lng
  // this is because we don't want to spam our geoip api more than once per day in the event that we can't lat/lng the user via ip
  const haveCachedGeo = cachedGeolocation && !isEmpty(cachedGeolocation)
  const hasLatLng = cachedGeolocation.latitude && cachedGeolocation.longitude

  const veryFreshCache = haveCachedGeo && geoWasSetOneDayAgo(cachedGeolocation)
  const freshCacheWithLatLng =
    haveCachedGeo && hasLatLng && isFresh(cachedGeolocation)

  return veryFreshCache || freshCacheWithLatLng
}

/**
 * Given an IP override via query string or from the browser itself, geolocate
 * the request via our API callback.
 *
 * @param ip Override request IP by providing one
 */
export default async function getLatLngFromIp(
  ip?: string,
): Promise<LatLngLocation> {
  const cachedGeolocation = getGeolocationFromLocalStorage()

  if (isValid(cachedGeolocation)) {
    return latLngFromGeolocation(cachedGeolocation)
  }

  const params = ip ? { ip } : undefined // override requester IP with query param
  const geolocationResponse = await internalJsonFetch<GeolocateResponseData>({
    authenticated: false,
    params,
    requestMethod: RequestMethod.GET,
    url: ApiRoutes.v1.callbacks.geolocate,
  })

  if (!geolocationResponse.success || !geolocationResponse.data?.success) {
    return {} // return empty object if the api lookup fails
  }

  setGeolocationInLocalStorage(geolocationResponse.data.value)
  if (geolocationResponse.data?.value?.zipcode) {
    prefillZipcodeForTrialApplication(geolocationResponse.data.value.zipcode)
  }

  return latLngFromGeolocation(geolocationResponse.data.value)
}

function geoWasSetOneDayAgo(cachedGeolocation: CachedGeoipResult) {
  // a geo that was checked one day ago will expire in TTL - 1 days from now
  const ttlDaysFromNow = addDays(new Date(), TTL_DAYS - 1)
  return cachedGeolocation.expiresAt! > ttlDaysFromNow.getTime()
}

export function isFresh(
  cachedGeolocation: CachedGeoipResult,
  now = new Date(),
) {
  if (!cachedGeolocation || isEmpty(cachedGeolocation)) {
    return false // I think this is actually supposed to be false - this isn't really "fresh"
  }

  // Only fresh if we have a future expiresAt
  if (
    !cachedGeolocation?.expiresAt ||
    cachedGeolocation.expiresAt < now.getTime()
  ) {
    return false
  }

  // Not fresh if no version or version is less than current
  if (
    !cachedGeolocation?.version ||
    cachedGeolocation.version < GEOSTORE_VERSION
  ) {
    return false
  }

  return true
}

function getExpiresAt(from = new Date()) {
  from.setDate(from.getDate() + TTL_DAYS)
  return from.getTime()
}

function prefillZipcodeForTrialApplication(freshZipcode?: string) {
  const trialApplicationForm = new TrialApplicationForm()
  const zipcodeInTrialApplication = trialApplicationForm.get('zipcode')
  if (!zipcodeInTrialApplication && typeof freshZipcode === 'string') {
    trialApplicationForm.set('zipcode', freshZipcode)
  }
}

/**
 * Retrieve from local storage the results from Geolocation IP information.
 */
export function getGeolocationFromLocalStorage() {
  const geostore = new LocalStorageItem(GEOSTORE_LOCAL_STORAGE_KEY)
  const cachedGeolocation = geostore.getItem() as CachedGeoipResult
  if (
    cachedGeolocation &&
    !isEmpty(cachedGeolocation) &&
    isFresh(cachedGeolocation)
  ) {
    return cachedGeolocation
  }

  return {}
}

function setGeolocationInLocalStorage(geoipResult: GeoipResult) {
  const geostore = new LocalStorageItem(GEOSTORE_LOCAL_STORAGE_KEY)
  const expiresAt = getExpiresAt()
  const version = GEOSTORE_VERSION
  geostore.setItem({ ...geoipResult, expiresAt, version })
}
