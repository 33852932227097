import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import { UpsellFormId } from '@lib/typeform/consts'

const ExternalRoutes = {
  apolloPeopleMatch: 'https://api.apollo.io/v1/people/match',
  appleHealthRecordDownloadsVideo: 'https://youtu.be/lyVP91a3PJI?t=109',
  appleHealthRecordExportVideo: 'https://youtu.be/Qa7KEGxLbtc',
  appointmentFollowUpTypeform: 'https://form.typeform.com/to/HYgjssir',
  appointmentSchedulingTypeform: 'https://form.typeform.com/to/uk18rJ2Z',
  assetsCdnBaseUrl: 'https://assets.withpower.com',
  athenaBaseUrl: 'https://athena.ohdsi.org/api/v1/concepts',
  blueprintWhitePaperTypeform: 'https://form.typeform.com/to/h3EJ7xgQ',
  calendlyEventZapierUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/38u1rib/',
  calendlySspDemo:
    'https://calendly.com/power-site-staff-portal/site-staff-demo',
  careers:
    'https://trypower.notion.site/Careers-at-Power-f885aa53d8d14a288dd154f13b644e4a',
  claimDoctorProfile: 'https://form.typeform.com/to/VBh4wCHx',
  claimHospitalProfile: 'https://form.typeform.com/to/dDqA1iyW',
  clinicalTrialsGov: 'https://clinicaltrials.gov/',
  crosTypeform: 'https://form.typeform.com/to/OtM3vmB3',
  dailyApiBaseUrl: 'https://api.daily.co/v1',
  dailyCallRoomBaseUrl: 'https://withpower.daily.co',
  datavant: {
    auth: 'https://api.datavant.io/v2/oauth2/token',
    document: (uuid: string) => `https://api.datavant.io/v2/documents/${uuid}`,
    documents: 'https://api.datavant.io/v2/documents',
    order: (id: string) => `https://api.datavant.io/v2/orders/${id}`,
    orderSubmit: (id: string) =>
      `https://api.datavant.io/v2/orders/${id}/submit`,
    orders: 'https://api.datavant.io/v2/orders/',
    patientAuth: (orderId: string, queryId: string) =>
      `https://api.datavant.io/v2/orders/${orderId}/queries/${queryId}/supporting-documents/patient-authorizations`,
    project: (id: string) => `https://api.datavant.io/v2/projects/${id}`,
    projects: 'https://api.datavant.io/v2/projects/',
    queries: (orderId: string, queryId: string) =>
      `https://api.datavant.io/v2/orders/${orderId}/queries/${queryId}`,
    query: (orderId: string) =>
      `https://api.datavant.io/v2/orders/${orderId}/queries`,
  },
  demoSetupTypeform: 'https://form.typeform.com/to/UkMjNVg9',
  documo: {
    preview: {
      downloadFax: (externalMessageId: string) =>
        `https://api.sandbox.documo.com/v1/fax/${externalMessageId}/download`,
      sendFax: `https://api.sandbox.documo.com/v1/faxes`,
    },
    production: {
      downloadFax: (externalMessageId: string) =>
        `https://api.documo.com/v1/fax/${externalMessageId}/download`,
      sendFax: `https://api.documo.com/v1/faxes`,
    },
  },
  exampleDataNoteAnalysis:
    'https://academic.oup.com/gigascience/article/10/12/giab084/6489123',
  facebookGroupCreation: 'https://www.facebook.com/groups/create',
  facebookHome: 'https://www.facebook.com/',
  facebookMessengerShare: 'https://www.facebook.com/dialog/send',
  facebookShare: 'https://www.facebook.com/dialog/share',
  faxEventToZapierUrl: 'https://hooks.zapier.com/hooks/catch/6992009/31eq2v4/',
  feasibilityStudyTypeform: 'https://form.typeform.com/to/HLzjBGvI',
  flexpaBaseUrl: 'https://api.flexpa.com',
  flightcontrolInvalidateCloudfront:
    'https://app.flightcontrol.dev/api/services/invalidateCloudfront',
  geoip: (ip: string) => `https://geoip.maxmind.com/geoip/v2.1/city/${ip}`,
  graphiteInterlinkingApiUrl: 'https://api.graphitehq.com/il',
  huggingFace: {
    embedding:
      'https://dxnkwqylp9s8sal7.us-east-1.aws.endpoints.huggingface.cloud',
    rerank:
      'https://bcoh2d2sbjkr8n4c.us-east-1.aws.endpoints.huggingface.cloud',
    resume: ({ endpoint }: { endpoint: string }) =>
      `https://api.endpoints.huggingface.cloud/v2/endpoint/Power/${endpoint}/resume`,
    status: ({ endpoint }: { endpoint: string }) =>
      `https://api.endpoints.huggingface.cloud/v2/endpoint/Power/${endpoint}`,
  },
  inclusionPdfNCT05137912:
    'https://clinicaltrials.gov/ProvidedDocs/12/NCT05137912/ICF_000.pdf',
  linkedInShare: 'https://www.linkedin.com/sharing/share-offsite/',
  mapTilerMapIdUrl: (mapId: string) =>
    `https://api.maptiler.com/maps/${mapId}/style.json?key=U6h4Yyk9E2vA6TEG8BOM`,
  mapTilerStyleUrl: (token: string) =>
    `https://api.maptiler.com/maps/topo-v2-pastel/style.json?key=${token}`,
  merianAvatarImage: ({ name, size }: { name: string; size: number }) =>
    urlWithQueryParams('https://merian.withpower.com/api/avatar', {
      name,
      size: size.toString(),
    }),
  merianHeaderImage: ({
    height,
    title,
    width,
  }: {
    height: number
    title: string
    width: number
  }) =>
    urlWithQueryParams('https://merian.withpower.com/api/header', {
      h: height.toString(),
      title,
      w: width.toString(),
    }),
  merianOgImage: ({ text }: { text: string }) =>
    urlWithQueryParams('https://merian.withpower.com/api/og', { text }),
  merianReferralImageUrl: 'https://merian.withpower.com/api/ref',
  meshBaseUrl: 'https://id.nlm.nih.gov/mesh/sparql',
  midjourneyTnlApiUrlBase: 'https://api.thenextleg.io/v2',
  newlyPurchasedPhoneNumberUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/bvpn47x/',
  npiApiUrl: 'https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search',
  openGraphImageUrl: (text: string, { markdown } = { markdown: false }) =>
    `https://pwr-og-img.withpower.com/${encodeURIComponent(text)}.png${
      markdown ? '?md=1' : ''
    }`,
  patientNavigatorsOnboardingCalendly:
    'https://calendly.com/power-patient-navigators/patient-navigation-call',
  playhtApiBaseUrl: 'https://play.ht/api',
  providerDemoTypeform: 'https://form.typeform.com/to/Mts9URJI',
  publicationSubmissionGuidelines:
    'https://ctdn.withpower.com/submission-guidelines',
  quora: 'https://www.quora.com/',
  redditAccessToken: 'https://www.reddit.com/api/v1/access_token',
  redditAuthorization: 'https://www.reddit.com/api/v1/authorize',
  redditShare: `https://www.reddit.com/submit`,
  referralTypeform: 'https://form.typeform.com/to/VeKwwjkk',
  representationWhitePaperTypeform: 'https://form.typeform.com/to/vWKwIvlb',
  scholarshipTypeform: 'https://form.typeform.com/to/lRDUtniR',
  securityAlertTypeform: 'https://form.typeform.com/to/xJXGl4Wv',
  serpApiBaseUrl: 'https://serpapi.com/search.json',
  signwellApiBaseUrl: 'https://www.signwell.com/api/v1',
  signwellWebhookEventToZapierUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/3hnsqh5/',
  slackApiBase: 'https://slack.com/api',
  stuffThatWorksHome: 'https://www.stuffthatworks.health/',
  subRedditShare: (subReddit: string) =>
    `https://www.reddit.com/r/${subReddit}/submit`,
  treatmentSupportDossierLander:
    'https://apply.withpower.com/treatment-support-dossier',
  trialApplicationZapierUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/biabpou/',
  trialProFreeTrialStartedZapierUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/327uyis/',
  trialProOnboardingZapierUrl:
    'https://hooks.zapier.com/hooks/catch/6992009/bc4dz7h/',
  twitterShare: 'https://twitter.com/intent/tweet',
  umlsBaseUrl: 'https://uts-ws.nlm.nih.gov/rest',
  universityOfChicagoInternshipTypeform:
    'https://form.typeform.com/to/g7e7lfOr',
  updateTrialDetailsTypeform: 'https://form.typeform.com/to/aJBatMxe',
  upsellEmailMarketingCreditsTypeform: `https://form.typeform.com/to/${UpsellFormId.EmailMarketingCredit}`,
  upsellEmailRecordsTypeform: `https://form.typeform.com/to/${UpsellFormId.EmailEMRUnlock}`,
  upsellInProductUnlockRecordsTypeform: `https://form.typeform.com/to/${UpsellFormId.InProductEMRUnlock}`,
  valxDUrl: 'https://d3ei5zjmhaqobx.cloudfront.net/api/parse',
  verifyMySite: 'https://verifymysite.withpower.com/',
  zeroBounceEmailValidation: 'https://api.zerobounce.net/v2/validate',
}

export default ExternalRoutes
