import {
  DirectoryLinks,
  topTenCityDirectories,
  topTenOtherConditionDirectories,
  topTenPsychologyConditionDirectories,
  topTenStateDirectories,
  topTenTreatmentDirectories,
} from '@components/app/DirectoryLinks'
import StructuredData from '@components/app/StructuredData'
import { Icon } from '@components/primitives/Icon'
import type { PageWidth } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import { ResponsivePageMargin } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faMapPin } from '@fortawesome/pro-regular-svg-icons/faMapPin'
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'
import { faBalanceScale } from '@fortawesome/pro-solid-svg-icons/faBalanceScale'
import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons/faShieldHalved'
import {
  colors,
  type BackgroundColor,
  type ColorGroup,
  type TextColor,
} from '@lib/colors'
import { SUPPORT_EMAIL } from '@lib/messages/constants/emailAddresses'
import Routes from '@lib/routes'
import ExternalRoutes from '@lib/routes/ExternalRoutes'
import absoluteUrl from '@lib/routes/helpers/absoluteUrl'
import { jsonLdScriptProps } from 'react-schemaorg'
import type { Organization } from 'schema-dts'
import PowerLogo from '../PowerLogo'
import { BackToTopText } from './BackToTopText'
import Guarantee from './Guarantee'

/** The section immediately below the home page's hero. **/
export function Footer({
  className,
  colorGroup = 'primary',
  hideGuaranteeBar = false,
  maxWidth,
}: {
  className?: string
  colorGroup?: ColorGroup
  hideGuaranteeBar?: boolean
  maxWidth?: PageWidth
}) {
  const colorConfig = getColorConfigFromColorGroup(colorGroup)

  return (
    <div>
      <StructuredData
        id={`with-power-organization`}
        scriptTagProps={jsonLdScriptProps<Organization>({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          'address': {
            '@type': 'PostalAddress',
            'addressLocality': 'San Francisco, California',
            'postalCode': '94107',
            'streetAddress': '1045 Sansome St., Suite 321',
          },
          'description':
            'Power makes it easy for patients to find their perfect clinical trial. Filter by city, condition, and phase to quickly identify cutting edge therapies for any condition.',
          'email': 'hello(at)withpower.com',
          'image': ExternalRoutes.openGraphImageUrl(
            '**🧬 Power Life Sciences**<br /><br />The easiest way for patients to find promising new treatments',
            { markdown: true },
          ),
          'name': 'Power Clinical Trials',
          'telephone': '(415) 900-4227',
          'url': absoluteUrl(Routes.home),
        })}
      />

      <div
        className={classMerge(
          colorConfig.backgroundColor,
          'absolute',
          'left-0',
          'w-full',
          className,
        )}
      >
        <ResponsivePageMargin contentWidth={maxWidth}>
          <div
            className={classMerge('pb-8 pt-4 lg:pt-8', {
              'lg:pt-8': !hideGuaranteeBar,
            })}
          >
            {!hideGuaranteeBar && (
              <div
                className={classMerge(
                  'flex',
                  'flex-col',
                  'items-center',
                  'mx-auto',
                  'lg:flex-row',
                  'lg:items-stretch',
                  'justify-between',
                  'gap-4',
                )}
              >
                <Guarantee
                  heading='Unbiased Results'
                  icon={faBalanceScale}
                  subheading='We believe in providing patients with all the options.'
                />
                <Guarantee
                  heading='Your Data Stays Your Data'
                  icon={faShieldHalved}
                  subheading="We only share your information with the clinical trials you're trying to access."
                />
                <Guarantee
                  heading='Verified Trials Only'
                  icon={faBadgeCheck}
                  subheading='All of our trials are run by licensed doctors, researchers, and healthcare companies.'
                />
              </div>
            )}
            <div
              className='mt-4 flex w-full flex-row justify-between'
              id='logoBack2top'
            >
              <div className='flex items-center'>
                <PowerLogo
                  iconColor={colorConfig.logoColor}
                  iconOpacity={0.6}
                  nameColor={colorConfig.logoColor}
                  size='Large'
                />
              </div>
              <BackToTopText color={colorConfig.textColor} />
            </div>
            <div
              className='mx-auto my-8 flex w-full flex-wrap justify-between lg:flex-row lg:flex-nowrap'
              id='extraInfo'
            >
              <div className='grid grid-cols-1 gap-4 md:grid-cols-6' id='links'>
                <ContactInfo colorConfig={colorConfig} />
                <div>
                  <Text
                    className={classMerge('text-left', colorConfig.headerColor)}
                    styleName='p-tiny-strong'
                    value='Conditions'
                  />
                  <DirectoryLinks
                    directories={topTenOtherConditionDirectories}
                    textColor={colorConfig.textColor}
                  />
                </div>
                <div>
                  <Text
                    className={classMerge('text-left', colorConfig.headerColor)}
                    styleName='p-tiny-strong'
                    value='Locations'
                  />
                  <DirectoryLinks
                    directories={topTenStateDirectories}
                    textColor={colorConfig.textColor}
                  />
                </div>
                <div>
                  <Text
                    className={classMerge('text-left', colorConfig.headerColor)}
                    styleName='p-tiny-strong'
                    value='Psychology Related'
                  />
                  <DirectoryLinks
                    directories={topTenPsychologyConditionDirectories}
                    textColor={colorConfig.textColor}
                  />
                </div>
                <div>
                  <Text
                    className={classMerge('text-left', colorConfig.headerColor)}
                    styleName='p-tiny-strong'
                    value='Treatments'
                  />
                  <DirectoryLinks
                    directories={topTenTreatmentDirectories}
                    textColor={colorConfig.textColor}
                  />
                </div>
                <div>
                  <Text
                    className={classMerge('text-left', colorConfig.headerColor)}
                    styleName='p-tiny-strong'
                    value='Cities'
                  />
                  <DirectoryLinks
                    directories={topTenCityDirectories}
                    textColor={colorConfig.textColor}
                  />
                </div>
              </div>
            </div>
            <div className='flex' id='termsOfService'>
              <Link href={Routes.termsOfService}>
                <Text
                  className={classMerge(
                    'mr-8 text-left',
                    colorConfig.textColor,
                  )}
                  styleName='p-tiny'
                  value='Terms of Service'
                />
              </Link>
              <Link href={Routes.privacyPolicy}>
                <Text
                  className={classMerge(
                    'mr-8 text-left',
                    colorConfig.textColor,
                  )}
                  styleName='p-tiny'
                  value='Privacy Policy'
                />
              </Link>
              <Link href={Routes.security}>
                <Text
                  className={classMerge('text-left', colorConfig.textColor)}
                  styleName='p-tiny'
                  value='Security'
                />
              </Link>
            </div>
          </div>
        </ResponsivePageMargin>
      </div>
    </div>
  )
}

type ColorConfig = {
  backgroundColor: BackgroundColor | string | undefined
  headerColor: TextColor
  iconColor: TextColor
  logoColor: string
  textColor: TextColor
}

function getColorConfigFromColorGroup(colorGroup: ColorGroup): ColorConfig {
  switch (colorGroup) {
    case 'blue':
      return {
        backgroundColor: 'bg-blue800',
        headerColor: 'text-blue200',
        iconColor: 'text-blue200',
        logoColor: colors.white,
        textColor: 'text-white',
      }
    case 'neutral':
      return {
        backgroundColor: 'bg-neutral900',
        headerColor: 'text-primary200',
        iconColor: 'text-primary200',
        logoColor: colors.neutral200,
        textColor: 'text-white',
      }
    case 'warm':
      return {
        backgroundColor: 'bg-warm100',
        headerColor: 'text-warm700',
        iconColor: 'text-warm700',
        logoColor: colors.warm700,
        textColor: 'text-warm900',
      }
    default:
      return {
        backgroundColor: 'bg-footerPurple',
        headerColor: 'text-primary200',
        iconColor: 'text-primary200',
        logoColor: colors.neutral200,
        textColor: 'text-white',
      }
  }
}

const ContactInfo = ({ colorConfig }: { colorConfig: ColorConfig }) => {
  return (
    <div id='companyInfo'>
      <div>
        <address className='flex-col not-italic'>
          <div className='flex items-baseline'>
            <Icon
              className={`mr-2 ${colorConfig.iconColor}`}
              icon={faMapPin}
              size='sm'
            />
            <Text
              className={classMerge('inline text-left', colorConfig.textColor)}
              styleName='p-tiny'
              value='1045 Sansome St, Suite 321, San Francisco, CA'
            />
          </div>
          <a className='block py-8' href={`mailto:${SUPPORT_EMAIL}`}>
            <Icon
              className={`mr-2 ${colorConfig.iconColor}`}
              icon={faEnvelope}
              size='sm'
            />
            <Text
              className={classMerge('inline text-left', colorConfig.textColor)}
              styleName='p-tiny'
              value={SUPPORT_EMAIL}
            />
          </a>
          <a className='block' href='tel:4159004227'>
            <Icon className={`mr-2 ${colorConfig.iconColor}`} icon={faPhone} />
            <Text
              className={classMerge('inline text-left', colorConfig.textColor)}
              styleName='p-tiny'
              value='(415) 900-4227'
            />
          </a>
        </address>
      </div>
      <div className='mt-12'>
        <Link href={Routes.aboutUs}>
          <Text
            className={classMerge('text-left', colorConfig.textColor)}
            styleName='p-tiny'
            value='About Us'
          />
        </Link>
        <Link href={Routes.forProviders}>
          <Text
            className={classMerge('text-left', colorConfig.textColor)}
            styleName='p-tiny'
            value='Treatment Support Dossier'
          />
        </Link>
        <Link href={ExternalRoutes.updateTrialDetailsTypeform} rel='nofollow'>
          <Text
            className={classMerge('text-left', colorConfig.textColor)}
            styleName='p-tiny'
            value='Update A Trial Listing'
          />
        </Link>
        <Link href={ExternalRoutes.careers} rel='nofollow'>
          <div className='bg-hiringCta mt-5 flex h-11 w-44 items-center justify-center space-x-2 rounded-md backdrop-blur-lg md:w-auto'>
            <Text
              className='md:w-min lg:w-auto'
              styleName='p-tiny'
              value="We're hiring!"
            />
            <div className='flex size-5 items-center justify-center rounded-full bg-primary500'>
              <Text className='text-white' styleName='p-tiny' value='1' />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
